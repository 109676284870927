import React, { useState } from "react";

import { messages } from "../../../App";
import background from "../../../assets/images/greensunbgimg.png";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import { stores } from "../../../App";

const ResultSuccessPart = ({ orders = [], next = () => { } }) => {
    const [qrSelected, setQrSelected] = useState("");
    const [qrVisible, setQrVisible] = useState(false);
    const [storeVisible, setStoreVisible] = useState(false);

    return (
        <div className="resultSuccessPart bgColor bgImage bgImage-left">
            <div className="title">Félicitations ! 🎊</div>
            <div className="message">
                La planète vient d’économiser
                <span className="message-highlight">
                    {orders.length * 3000} L d’eau
                </span>
                grâce à vous !
            </div>
            <div className="description">
                {messages.successMessage}
            </div>
            <button className="storePopupBtn" onClick={() => setStoreVisible(true)}>
                Horaires d'ouvertures
            </button>
            <div className="orders-table">
                <table>
                    <thead>
                        <tr>
                            <th className="col1">#</th>
                            <th className="col4">Marque</th>
                            <th className="col2">Catégorie</th>
                            <th className="col3">Matière</th>
                            <th className="col2">Couleur</th>
                            <th className="col1">Taille</th>
                            <th className="col3">Etat</th>
                            <th className="col2">Le prix</th>
                            <th className="col2">QR</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders.map((val, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{val.brand}</td>
                                <td>{val.category}</td>
                                <td>{val.material}</td>
                                <td>{val.color}</td>
                                <td>{val.size}</td>
                                <td>{val.state}</td>
                                <td>{val.price} €</td>
                                <td>
                                    <img className="c-poiter" src={val.qrCode} height="70px" alt=""
                                        onClick={() => {
                                            setQrVisible(true);
                                            setQrSelected(val.qrCode);
                                        }}
                                    />
                                </td>
                            </tr>
                        ))}
                        {orders.length === 0 && (
                            <tr>
                                <td colSpan={10} className="text-center align-middle" style={{ height: 160 }}>
                                    Pas encore de commandes!
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div >
        

            <Modal visible={qrVisible} onClose={() => setQrVisible(false)}>
                <div className="qrCodeview">
                    <img src={qrSelected} height="100%" alt="" />
                </div>
            </Modal>

            <Modal visible={storeVisible} onClose={() => setStoreVisible(false)}>
                <div className="store-table">
                    <table className="table">
                        <tbody>
                            <tr>
                                <td className="border-bottom-0"></td>
                                {stores.map((val, index) =>
                                    <td key={index} className="orderRow storeName">
                                        {val.name}
                                    </td>
                                )}
                            </tr>
                            <tr>
                                <th >Adresse:</th>
                                {stores.map((val, index) => (
                                    <td key={index} className="orderRow">
                                        {val.address}
                                        <br />
                                        {val.zipCode + ", " + val.city}
                                    </td>
                                ))}
                            </tr>

                            <tr>
                                <th>
                                    Horaires
                                    <br />
                                    d'ouvertures:
                                </th>
                                {stores.map((s, index) => (
                                    <td className="orderRow">
                                        <div key={index}>
                                            {s.openingHours?.map((t) =>
                                                <div key={t["@id"]} className="scheduler-row">
                                                    <div>
                                                        {t.dayOfWeek}
                                                    </div>
                                                    <div>
                                                        {t.openTime} &#8594; {t.closeTime}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Modal>
        </div >
    )
}

export default ResultSuccessPart;
