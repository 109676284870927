import React, { useEffect, useRef, useState } from "react";

import background from "../../../assets/images/greensunbgimg_right.png";
import Button from "../../components/Button";


const PricePart = ({ result = {}, addToOrders = () => { }, next = () => { }, prev = () => { } }) => {
    const ref = useRef();
    const [added, setAdded] = useState(false);

    useEffect(() => {
        ref.current?.scrollIntoView();
    }, []);

    return (
        <div ref={ref} className="pricePart bgImage bgImage-right">
            <div className="title">Votre récapitulatif</div>
            <div className="filterBox d-flex flex-row align-self-center justify-content-around flex-wrap">
                <div className="filter">
                    <div className="name">Marque</div>
                    <div className="value">{result.brand.name}</div>
                </div>
                <div className="filter">
                    <div className="name">Catégorie</div>
                    <div className="value">{result.category.name+" " +result.category.children.name}</div>
                </div>
                <div className="filter">
                    <div className="name">Matière</div>
                    <div className="value">{result.material.name}</div>
                </div>
                <div className="filter">
                    <div className="name">Couleur</div>
                    <div className="value">{result.color.name}</div>
                </div>
                <div className="filter">
                    <div className="name">Taille</div>
                    <div className="value">{result.size.name}</div>
                </div>
                <div className="filter">
                    <div className="name">Etat</div>
                    <div className="value">{result.state.name}</div>
                </div>
            </div>
            <div className="price">{result.price ?? 0} €</div>
            <div className="description">C’est le prix auquel nous vous achèterons votre article ! </div>
            <div className="btnGroup d-flex flex-wrap justify-content-between">
                <Button className="btn-blue"
                    title="Je recommence"
                    onClick={prev} />
            </div>
        </div>
    )
}

export default PricePart;
