import React, { useEffect, useState } from "react";

import Screen from "./screen/Screen";
import config from "./config.json";
import { checkToken, getMyInfo } from "./service/api";

const addFavicon = (icon) => {
  let link = document.createElement("link");
  link.setAttribute("rel", "icon");
  link.setAttribute("href", icon);
  document.head.append(link);
}
let stores = [];
let messages = {
  successMessage: ""
};
const App = () => {

  const [appTheme, setAppTheme] = useState({});
  const [homeImage, setHomeImage] = useState("");
  const [googleScriptAnalytics, setGoogleScriptAnalytics] = useState("");

  useEffect(() => {
    checkToken().then(() => {
      getMyInfo().then((data) => {
        if (data) {
          document.title = data.mainStore?.company?.name;
          addFavicon(config.host_api + data.mainStore?.company?.customizer.faviconUrl);
          setAppTheme(data.mainStore?.company?.customizer);
          setHomeImage(config.host_api + data.mainStore?.company?.customizer.imageUrl );
          setGoogleScriptAnalytics( data.mainStore?.company?.customizer?.googleScriptAnalytics )
          stores = data.stores;
          messages.successMessage = data.mainStore?.company?.customizer.successMessage;
        }
      });
    })
  }, []);


  const css = `
    :root {
      --borderColor: ${appTheme.borderColor};
      --buttonConfirmColor: ${appTheme.buttonConfirmColor};
      --buttonNewColor: ${appTheme.buttonNewColor};
      --titleColor: ${appTheme.titleColor};
      --backgroundColor : ${appTheme.backgroundColor};
      --underTextColor: ${appTheme.underTextColor};
      --backgroundColorFooter : ${appTheme.backgroundColorFooter};
      --primaryLogo: ${appTheme.imageUrl ? ("url(" + config.host_api + appTheme.imageUrl + ")") : "none"};
      --storeLogo: ${appTheme.logoUrl ? ("url(" + config.host_api + appTheme.logoUrl + ")") : "none"};
    }
  `;

  return (
    <div className="App">
      <style>{css}</style>
      <Screen  homeImage={homeImage} googleScriptAnalytics={googleScriptAnalytics} />
    </div>
  );
}

export { stores, messages };
export default App;
