import React from "react";

const GoogleAnalytics = ({googleTag}) => {
    const googleAnalyticsResource = document.createElement('script');
    googleAnalyticsResource.src = "https://www.googletagmanager.com/gtag/js?id="+googleTag;
    document.head.appendChild(googleAnalyticsResource);

    const script = document.createElement("script");
    script.textContent = 'window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \''+googleTag+'\');';
    document.head.appendChild(script);

    return (
        <></>
    )
}

export default GoogleAnalytics;